// Settings
@import "scss/settings/settings.scss";

html {
  font-size: 10px;
  line-height: 1em;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  color: $color-white;
  background: $color-black;
  padding: 0;
  border: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-silver;
  font-weight: bold;
  text-decoration: underline;
}

p {
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0 0 2.8rem;
}

div {
  font-size: 2rem;
  line-height: 2rem;
  padding: 0;
  margin: 0;
}


h2 {
  font-size: 4.8rem;
  line-height: 6rem;
  padding: 0;
  margin: 0 0 2.8rem;
}

ol,
ul {
  padding: 0;
  margin: 0 0 2.8rem;
  li {
    font-size: 2rem;
    line-height: 2.8rem;
    padding: 0;
    margin: 0 0 2.8rem 4rem;
  }
}
