// Settings
@import "../../settings/settings.scss";

%padding-x {
    padding-left: 24px;
    padding-right: 24px;
    @media (min-width: $breakpoint-sm) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media (min-width: $breakpoint-md) {
        padding-left: 48px;
        padding-right: 48px;
    }
    @media (min-width: $breakpoint-lg) {
        padding-left: 64px;
        padding-right: 64px;
    }
    @media (min-width: $breakpoint-xl) {
        padding-left: 80px;
        padding-right: 80px;
    }
}

%padding-y {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (min-width: $breakpoint-sm) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    @media (min-width: $breakpoint-md) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @media (min-width: $breakpoint-lg) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width: $breakpoint-xl) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}
