$width-max-readable: 550px;
%width-max-readable {
    max-width: $width-max-readable;
    // @media (min-width: $breakpoint-sm) {
    //     padding: 0 32px;
    // }
    // @media (min-width: $breakpoint-md) {
    //     padding: 0 48px;
    // }
    // @media (min-width: $breakpoint-lg) {
    //     padding: 0 64px;
    // }
    // @media (min-width: $breakpoint-xl) {
    //     padding: 0 80px;
    // }
}
.width-max-readable {
    @extend %width-max-readable;
}

%width-max-content {
    // TODO
    max-width: $width-max-readable + 160px + 340px;
    // $menu-clearance: (60px + 96px + 60px);
    // $padding-to-deduct: 80px;
    // $margin-clearance: $menu-clearance - $padding-to-deduct;
    // max-width: $margin-clearance + 200px + 48px + $width-max-readable + 400px + $margin-clearance; // To suit App page layout
}
.width-max-content {
    @extend %width-max-content;
}
