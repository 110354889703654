// Settings
@import "../../../scss/settings/settings.scss";

// Vars
$item-size: 20%;
$item-max-size: 96px;
$logo-size: 36%;
$icon-size: 25%;
$num-items: 5; // Number of menu items

.Menu {
  // Styles
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: $item-max-size * $num-items;
  border-radius: 0;
  margin: 0 0 60px 0;
  position: sticky;
  z-index: 2;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.4);
  // Vars
  $position: 0;
  // Var Styles
  top: $position;
  left: $position;
  right: $position;
  .item {
    // Styles
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-red;
    width: $item-size;
    // Ratio: 1:1
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    &.item-logo {
      .content {
        width: $logo-size;
        height: $logo-size;
      }
    }
    &.item-home,
    &.item-privacy {
      .content {
        width: $icon-size;
        height: $icon-size;
      }
    }
    &.item-logo {
      background: $color-white;
    }
    &.active {
      background: $color-grey;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $icon-size;
      height: $icon-size;
      color: $color-white;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  // Vars
  $item-max-size: 80px;
  
  .Menu {

    // Centre-aligned:
    // width: auto;
    // border-radius: 8px;
    // margin: 32px auto;
    // $position: 32px;
    // top: $position;
    // max-width: calc(#{$breakpoint-sm} - 64px);




    // Styles
    // width: fit-content;
    width: auto;
    max-width: $item-max-size * $num-items;
    border-radius: 8px;
    margin: 32px;
    // Vars
    $position: 32px;
    // Var Styles
    top: $position;
    left: $position;
    .item {
      // Vars
      // $item-size: 96px;
      // $logo-size: 24px;
      // $icon-size: 16px;
      // Styles
      width: $item-size;
      height: $item-size;
      &.item-logo {
        .content {
          width: $logo-size;
          height: $logo-size;
        }
      }
      &.item-home,
      &.item-privacy {
        .content {
          width: $icon-size;
          height: $icon-size;
        }
      }
      .content {
        width: $icon-size;
        height: $icon-size;
      }
    }
  }
}
@media (min-width: $breakpoint-md) {
  .Menu {
    // Styles
    margin: 48px;
  }
}
@media (min-width: $breakpoint-lg) {
  .Menu {
    // Vars
    $position: 48px;
    // Var Styles
    top: $position;
    left: $position;
  }
}
@media (min-width: $breakpoint-xl) {
  .Menu {
    // Styles
    position: fixed;
    display: block;
    width: $item-max-size;
    margin: 0px;
    // Vars
    $position: 60px;
    // Var Styles
    top: $position;
    left: $position;
    .item {
      // Styles
      width: $item-max-size;
    }
  }
}
@media (min-width: $breakpoint-xxl) {
}
