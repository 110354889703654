// Mixins
@import "../../../scss/mixins/mixins.scss";
// Settings
@import "../../../scss/settings/settings.scss";

.heading {
  // Styles
  position: relative;
  color: $color-white;
  font-weight: bold;
  padding: 0;
  // Vars
  $font-size: 3.2rem;
  $line-height: ($font-size * 1.2);
  $margin-bottom: $font-size;
  // Var Styles
  font-size: $font-size;
  line-height: $line-height;
  // Responsive styles
  margin-bottom: $margin-bottom;
  // $margin-top: (
  //   bp-xs: 60px,
  //   bp-md: 90px,
  //   bp-xl: 120px,
  // );
  // @include responsive-values("margin-top", $margin-top);

  &:after {
    content: " ";
    background: $color-red;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 5px;
    margin-bottom: -5px;
  }
}

@media (min-width: $breakpoint-sm) {
  .heading {
    // Styles
    // Vars
    $font-size: 4.8rem;
    $line-height: ($font-size * 1.2);
    $margin-bottom: $font-size;
    // Var Styles
    font-size: $font-size;
    line-height: $line-height;
    margin-bottom: $margin-bottom;
    &:after {
      width: 60px;
      height: 10px;
      margin-bottom: -10px;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .heading {
    // Styles
    // Vars
    $font-size: 4.8rem;
    $line-height: ($font-size * 1.2);
    $margin-bottom: $font-size;
    // Var Styles
    font-size: $font-size;
    line-height: $line-height;
    margin-bottom: $margin-bottom;
    &:after {
      width: 60px;
      height: 10px;
      margin-bottom: -10px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .heading {
    // Vars
    $font-size: 4.8rem;
    $line-height: ($font-size * 1.2);
    $margin-bottom: $font-size;
    // Var Styles
    font-size: $font-size;
    line-height: $line-height;
    margin-bottom: $margin-bottom;
    &:after {
      width: 60px;
      height: 10px;
      margin-bottom: -10px;
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .heading {
    // Styles
    // Vars
    $font-size: 4.8rem;
    $line-height: ($font-size * 1.2);
    $margin-bottom: $font-size;
    // Var Styles
    font-size: $font-size;
    line-height: $line-height;
    margin-bottom: $margin-bottom;
    &:after {
      width: 60px;
      height: 10px;
      margin-bottom: -12px;
    }
  }
}
