.IconSocialDribbble {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // Ratio: square
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  svg {
    display: block;
    width: 100%;
    // height: 100%;
  }
}
