// Mixins
@import "../../../scss/mixins/mixins.scss";
// Placeholders
@import "../../../scss/placeholders/placeholders.scss";
// Settings
@import "../../../scss/settings/settings.scss";

.footer {
  @extend %padding-x;
  @extend %padding-y;

  position: relative;
  text-align: left;
  background: $color-blue;

  @include respond-to($breakpoint-xl) {
    $menu-offset: 60px + 96px + 60px;
    padding-left: $menu-offset;
  }

  // .content {
  //   @extend %width-max-readable;
  //   margin: 0 auto;

    .title {
      // font-size: 3rem;
      // line-height: 3rem;
      font-weight: bold;
    }

    .social {
      display: flex;
      align-items: flex-end;
      margin-bottom: 24px;
      .icon {
        width: 24px;
        margin-right: 12px;
      }
      .text {
        width: 100%;
        text-align: left;
        .app {
          font-size: 1.2rem;
          line-height: 1.2rem;
          margin-bottom: 4px;
        }
        .link {
          font-size: 1.6rem;
          line-height: 1.6rem;
          font-weight: bold;
          a {
            color: $color-white;
            text-decoration: none;
          }
        }
      }
    }

    .copyright {
      // Vars
      $font-size: 1.6rem;
      $line-height: 1em;
      $margin-bottom: $font-size;
      // Styles
      font-size: $font-size;
      line-height: $line-height;
      margin-top: 48px;
      margin-bottom: $margin-bottom;
    }

    .company {
      // Vars
      $font-size: 1.2rem;
      $line-height: ($font-size * 1.5); // Note: Higher multiplier for lower numbers?
      $margin-bottom: $font-size;
      // Styles
      font-size: $font-size;
      line-height: $line-height;
      margin-bottom: $margin-bottom;
    }

    .api {
      // Vars
      $font-size: 1.2rem;
      $line-height: 1em;
      $margin-bottom: $font-size;
      // Styles
      font-size: $font-size;
      line-height: $line-height;
      margin-bottom: $margin-bottom;
    }

  // }

}

// Diagonal background
.footer {
  // $background-colours: (
  //   bp-xs: red,
  //   bp-sm: yellow,
  //   bp-md: green,
  //   bp-lg: blue,
  //   bp-xl: purple,
  // );
  // @include responsive-values("background-color", $background-colours);

  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  // Vars: Degrees
  $diagonal-skew-degrees-bp-xs: 15;
  $diagonal-skew-degrees-bp-sm: 12;
  $diagonal-skew-degrees-bp-md: 10;
  $diagonal-skew-degrees-bp-lg: 8;
  $diagonal-skew-degrees-bp-xl: 5;
  // Vars: Size
  $diagonal-size-bp-xs: ($diagonal-skew-degrees-bp-xs * 2);
  $diagonal-size-bp-sm: ($diagonal-skew-degrees-bp-sm * 2);
  $diagonal-size-bp-md: ($diagonal-skew-degrees-bp-md * 2);
  $diagonal-size-bp-lg: ($diagonal-skew-degrees-bp-lg * 2);
  $diagonal-size-bp-xl: ($diagonal-skew-degrees-bp-xl * 2);
  // Vars: Padding Top
  $diagonal-padding-top: (
    bp-xs: 24px,
    bp-sm: 32px,
    bp-md: 48px,
    bp-lg: 64px,
    bp-xl: 80px,
  );
  // $diagonal-padding-top: (
  //   bp-xs: (
  //     $diagonal-size-bp-xs * 1,
  //   ) + vw,
  //   bp-sm: (
  //     $diagonal-size-bp-sm * 1,
  //   ) + vw,
  //   bp-md: (
  //     $diagonal-size-bp-md * 1,
  //   ) + vw,
  //   bp-lg: (
  //     $diagonal-size-bp-lg * 1,
  //   ) + vw,
  //   bp-xl: (
  //     $diagonal-size-bp-xl * 1,
  //   ) + vw,
  // );
  // Vars: Margin Top
  $diagonal-margin-top: (
    bp-xs: calc(#{$diagonal-size-bp-xs * 1}vw + 24px),
    bp-sm: calc(#{$diagonal-size-bp-sm * 1}vw + 32px),
    bp-md: calc(#{$diagonal-size-bp-md * 1}vw + 48px),
    bp-lg: calc(#{$diagonal-size-bp-lg * 1}vw + 64px),
    bp-xl: calc(#{$diagonal-size-bp-xl * 1}vw + 80px),
  );
  // Var Styles
  padding-top: 0;
  // @include responsive-values("padding-top", $diagonal-padding-top);
  @include responsive-values("margin-top", $diagonal-margin-top);

  // background: rgba(0, 255, 0, 0.25); // TODO REMOVE

  &:before {
    // Styles
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    // transform: skewy($diagonal-skew-degrees + deg);
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
    background: $color-blue;
    // background: rgba(255, 0, 0, 0.5); // TODO REMOVE
    // Var Styles
    $diagonal-skewy: (
      bp-xs: skewy($diagonal-skew-degrees-bp-xs + deg),
      bp-sm: skewy($diagonal-skew-degrees-bp-sm + deg),
      bp-md: skewy($diagonal-skew-degrees-bp-md + deg),
      bp-lg: skewy($diagonal-skew-degrees-bp-lg + deg),
      bp-xl: skewy($diagonal-skew-degrees-bp-xl + deg),
    );
    $diagonal-height-bp-xs: ($diagonal-size-bp-xs * 1);
    $diagonal-height-bp-sm: ($diagonal-size-bp-sm * 1);
    $diagonal-height-bp-md: ($diagonal-size-bp-md * 1);
    $diagonal-height-bp-lg: ($diagonal-size-bp-lg * 1);
    $diagonal-height-bp-xl: ($diagonal-size-bp-xl * 1);
    $diagonal-height: (
      bp-xs: $diagonal-height-bp-xs + vw,
      bp-sm: $diagonal-height-bp-sm + vw,
      bp-md: $diagonal-height-bp-md + vw,
      bp-lg: $diagonal-height-bp-lg + vw,
      bp-xl: $diagonal-height-bp-xl + vw,
    );
    $diagonal-top: (
      bp-xs: (
        0 - ($diagonal-height-bp-xs / 2),
      ) + vw,
      bp-sm: (
        0 - ($diagonal-height-bp-sm / 2),
      ) + vw,
      bp-md: (
        0 - ($diagonal-height-bp-md / 2),
      ) + vw,
      bp-lg: (
        0 - ($diagonal-height-bp-lg / 2),
      ) + vw,
      bp-xl: (
        0 - ($diagonal-height-bp-xl / 2),
      ) + vw,
    );
    @include responsive-values("transform", $diagonal-skewy);
    @include responsive-values("height", $diagonal-height);
    @include responsive-values("top", $diagonal-top);
  }
}

// @media (min-width: $breakpoint-sm) {
//   .footer {
//   }
// }
// @media (min-width: $breakpoint-md) {
//   .footer {
//   }
// }
// @media (min-width: $breakpoint-lg) {
//   .footer {
//   }
// }
// @media (min-width: $breakpoint-xl) {
//   .footer {
//   }
// }
