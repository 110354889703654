@import '../../settings/breakpoints/breakpoints';

// Create map of breakpoints
$breakpoints: (
    bp-xs: $breakpoint-xs,
    bp-sm: $breakpoint-sm,
    bp-md: $breakpoint-md,
    bp-lg: $breakpoint-lg,
    bp-xl: $breakpoint-xl,
    bp-xxl: $breakpoint-xl,
);

/*
 * Mixin: respond-to()
 * Used to apply styles to a single.
 *
 * Example:
 * @include respond-to($bp-sm) {
 *     display: flex;
 *     background: blue;
 * }
 *
 */
@mixin respond-to($bp) {
    @if $bp == "0px" {
        @content;
    } @else {
        @media (min-width: $bp) {
            @content;
        }
    }
}

/*
 * Mixin: responsive-values()
 * Used to apply styles to multiples breakpoints.
 *
 * Example:
 * $header-font-sizes: ( bp-xs: 22px, bp-sm: 28px, bp-md: 32px, bp-lg: 40px, bp-xl: 45px );
 * @include responsive-values("font-size", $header-font-sizes);
 *
 */
@mixin responsive-values($property, $map) {
    @debug "responsive-values() #{$property} #{$map}";
    @each $breakpoint-name, $breakpoint in $breakpoints {
        @include respond-to($breakpoint) {
            @if map-has-key($map, $breakpoint-name) {
                #{$property}: map-get($map, $breakpoint-name);
            // } @else {
            //     @warn "Unknown key '#{$breakpoint-name}' in map.";
            }
        }
    }
}
