// Settings
@import "../../../scss/settings/settings.scss";

.Logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // Ratio: square
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .background {
    display: block; // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    height: 100%;
    padding: 2px;
    // width: 28px;
    // height: 28px;
    border-radius: 50%;
    // overflow: hidden;
    background: $color-white;
    // Ratio: square
    // &:after {
    //   content: "";
    //   display: block;
    //   padding-bottom: 100%;
    // }
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    svg {
      display: block;
      width: 100%;
      height: 100%;
      // &:after {
      //   content: "";
      //   display: block;
      //   padding-bottom: 100%;
      // }
      border-radius: 50%;
    }
  }
}

// @media (min-width: $breakpoint-md) {
//   .Logo {
//     .background {
//       width: 32px;
//       height: 32px;

//       svg {
//         width: 32px;
//         height: 32px;
//       }
//     }
//   }
// }
