// Mixins
@import "./scss/mixins/mixins.scss";
// Placeholders
@import "./scss/placeholders/placeholders.scss";
// Settings
@import "./scss/settings/settings.scss";

.App {
  .app-body {
    @extend %padding-x;
    margin: 0 auto;
    // Responsive
    @include respond-to($breakpoint-xl) {
      $menu-offset: 60px + 96px + 60px;
      padding-left: $menu-offset;
    }
    
  }

  // height: 100vh;
  // width: 100vw;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // padding: 0 24px;

  // .app-menu {
  //   display: block;
  // }
  // .app-content {
  //   display: block;
  // }
}

