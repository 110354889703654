// Mixins
@import "../../../scss/mixins/mixins.scss";
// Placeholders
@import "../../../scss/placeholders/placeholders.scss";
// Settings
@import "../../../scss/settings/settings.scss";

// Vars
$img-width: 340px;

.content-stack {
    display: block;
    margin: 0 auto;
    // Var Styles
    max-width: $width-max-readable;
    // Responsive styles
    $margin-top: (
        bp-xs: 32px,
        bp-sm: 48px,
        bp-md: 64px,
        bp-lg: 80px,
        bp-xl: 96px,
    );
    @include responsive-values("margin-top", $margin-top);
    // Children
    .content-stack-primary {
        @extend %margin-0-auto;
        @extend %width-max-readable;
    }
    .content-stack-secondary {
        // Placeholder styles
        @extend %margin-0-auto;
        // Var styles
        // width: $img-width;
        // Styles
        text-align: center;
        img {
            width: 100%;
            max-width: 340px;
            height: auto;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .content-stack {
    }
}
@media (min-width: $breakpoint-md) {
    .content-stack {
    }
}
@media (min-width: $breakpoint-lg) {
    $spacer: 48px;
    .content-stack {
        display: flex;
        width: 100%;
        max-width: $width-max-readable + $spacer + $img-width;
        justify-content: center;
        .content-stack-primary {
            width: calc(100% - (#{$img-width} + #{$spacer}));
        }
        .content-stack-secondary {
            width: $img-width;
            margin-left: $spacer;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    $spacer: 64px;
    .content-stack {
        max-width: $width-max-readable + $spacer + $img-width;
        .content-stack-primary {
            width: calc(100% - (#{$img-width} + #{$spacer}));
        }
        .content-stack-secondary {
            margin-left: $spacer;
        }
    }
}
@media (min-width: $breakpoint-xxl) {
    $spacer: 80px;
    .content-stack {
        max-width: $width-max-readable + $spacer + $img-width;
        .content-stack-primary {
            width: calc(100% - (#{$img-width} + #{$spacer}));
        }
        .content-stack-secondary {
            margin-left: $spacer;
        }
    }
}
