$color-white: #ffffff;
$color-black: #0a0d11;

$color-red: #ea2c36;
$color-pink: #fc555d;

$color-blue: #036aff;
$color-silver: #bbcadf;

$color-grey: #292f36;
